<template>
    <v-dialog max-width="400px" v-model="showDialog">
        <v-card>
            <v-card-title>Editar Store {{store.name}}</v-card-title>
            <v-card-text>
                <v-select :items="storeGroupsList" v-model="store.store_group_id"></v-select>
            </v-card-text>
            <v-card-actions>
                <v-btn :disabled="!store.store_group_id" @click="sendForm" color="success">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return{
            showDialog: false,
            store: {},
            storeGroupsList: [],
        }
    },
    created(){
        this.fetchStoreGroups();
    },
    mounted(){
        this.$parent.$on('openDialogEditStore', (item) => {
            var vm = this;
            this.axios({
                url: 'admin/stores/'+item.id,
                method: 'GET'
            }).then( response => {
                vm.store = response.data;
                vm.showDialog = true;
            }).catch( error => {
                console.log(error);
            });
        });
    },
    methods:{
        fetchStoreGroups(){
            var vm = this;
            this.axios({
                url: 'admin/store_groups',
                method: 'GET',
            }).then( response => {
                vm.storeGroupsList = response.data.map(e => ({text: e.name, value: e.id}));
            })
        },
        sendForm(){
            var vm = this;
            this.axios({
                url: 'admin/stores/'+this.store.id,
                method: 'PUT',
                data: this.store,
            }).then( response => {
                vm.$emit('success');
                vm.showDialog = false;
            }).catch( error => {
                console.log(error);
            });
        }
    }
}
</script>